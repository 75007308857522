export const CLOUD_HEIGHT = [
  0,
  11,
  7,
  12,
  9,
  3,
  2,
  5,
  8,
  11,
  3,
  2,
  5,
  8,
  11,
  0,
  10,
  6,
  2,
  6,
  8,
  14,
  9,
  13,
  10,
  3,
  9,
  6,
  13,
  10,
  8,
  11,
  0,
  10,
  6,
  5,
  11,
  5,
  8,
  11,
  0,
  3,
  0,
  3,
  2,
  5,
  8,
  11,
  0,
  3,
  9,
  6,
  13,
  10,
  3,
  9,
  6,
  13,
  10,
  6,
  5,
  13,
  12,
];

export const CLOUD_LEFT = [
  0,
  -12,
  5,
  12,
  -8,
  -40,
  -50,
  -33,
  -24,
  -45,
  23,
  31,
  40,
  23,
  29,
  60,
  80,
  90,
  55,
  92,
  66,
  88,
  86,
  76,
  35,
  5,
  8,
  -5,
  80,
  90,
  55,
  92,
  66,
  88,
  86,
  76,
  -8,
  -40,
  -50,
  -33,
  -24,
  -45,
  24,
  31,
  40,
  23,
  29,
  60,
  80,
  90,
  55,
];