const theme = {
  global: {
    font: {
      family: 'Poppins',
      size: '16px',
      height: '20px',
    },
  },
};

export default theme;